import axios from "axios";

const HOST = process.env.REACT_APP_API_HOST;

const instance = axios.create({
  baseURL: `${HOST}`,
  timeout: 60000,
  validateStatus(status) {
    return status >= 200 && status <= 500;
  },
});

const request = async (
  config
) => {
  try {
    const { data } = await instance.request(config);
    return data;
  } catch (err) {
    const message = "Request Error";
    console.error(message);
    return {
      code: -1,
      message,
      data: null,
    };
  }
};

export default request;
