import { IconButton, Menu, MenuItem } from "@mui/material";
import styled from '@emotion/styled';
import { useState } from "react";
import fireIcon from "../assets/img/fire.png";
import closeIcon from "../assets/img/close.svg";
import threeIcon from "../assets/img/three_bar.svg";


const IconMenu = styled(IconButton)`
  box-sizing: border-box;
  padding: 5px;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #7CD0FF;
  background-color: #1B1C21;
  box-shadow: 4px 8px 24px 0px rgba(20, 6, 38, 0.30);
`
const MobileItem = styled(MenuItem)`
  padding: 0;
  a{
    padding: 0 28px 0 17px;
    width: 100%;
    height: 100%;
    text-align: left;
    font-size: 14px;
    color: #A0A0A0;
  }
`

const NavWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 58px;
  line-height: 42px;
  flex: 1;
  text-wrap: nowrap;
  margin-right: 58px;
  font-weight: bold;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.80) ;
    font-size: 16px;
    font-family: Lato;
    img {
      display: block;
      width: 20px;
      height: 20px;
    }
  }
  a: hover{
    background: var(--Linear, linear-gradient(115deg, #6DEAAC 6.48%, #51B1E6 86.01%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export function Nav({ isMobile }) {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const pages = [
    { to: "https://app.rolldex.io/", label: "Trade" },
    { to: "https://app.rolldex.io/pools", label: "Pools" },
    { to: "https://app.rolldex.io/points", label: "Points" },
    { to: "https://app.rolldex.io/leaderboard", label: "Leaderboard" },
    { to: "https://app.rolldex.io/referrals", label: "Referral" },
    { to: "https://rolldex.gitbook.io/rolldex", label: "Docs" },
    { to: "https://app.rolldex.io/highroller", label: "Campaigns", icon: fireIcon },
  ];

  if (isMobile) {
    return (
      <>
        <IconMenu
          aria-label="show menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          {anchorElNav ? <img src={closeIcon} alt="closeIcon" /> : <img src={threeIcon} alt="bar" />}
        </IconMenu>

        <Menu
          id="menu-appbar"
          sx={{ 
            mt: "48px",
          }}
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            style: {
              width: "124px",
              backgroundColor: "#43424E",
            },
          }}
          slotProps={{
            paper: {
              style: {
                borderRadius: "8px",
                backgroundColor: "rgba(255, 255, 255, 0.10)",
                backdropFilter: "blur(20px)",
              },
            },
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
        >
          {pages.map((page) => (
            <MobileItem key={page.to} onClick={handleCloseNavMenu}>
              <a href={page.to} target='_blank' rel="noreferrer">{page.label}</a>
            </MobileItem>
          ))}
        </Menu>
      </>
    );
  } else {
    return (
      <NavWrapper>
        {pages.map((page) => (
            <a key={page.to}  href={page.to} target='_blank' rel="noreferrer">
              {page.icon && <img src={page.icon} alt="icon" ></img>}
              <span>{page.label}</span>
            </a>
          ))}
      </NavWrapper>
    );
  }
}
