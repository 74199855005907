import React from 'react';
import homeBg1 from '../assets/img/home_bg1.png';
import homeBg2 from '../assets/img/home_bg2.png';
import homeBg3 from '../assets/img/home_bg3.png';
import { useTheme, useMediaQuery } from '@mui/material';
import styled from '@emotion/styled';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';

const LayBox = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #060A15;
  background-image: url(${homeBg1}), url(${homeBg2}), url(${homeBg3});
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: ${(props) => (props.matches ? 'top left, left 1460px, left 2820px' : 'top center, left 1335px, right 3640px')};
  background-size: ${(props) => (props.matches ? '100% 210px, 100% 208px, 100% 208px' : '1920px 1080px, 1920px 1066px, 1920px 1066px')};
`;

const Layout = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <LayBox matches={matches}>
      <Header />
      <div>
        <Outlet />
      </div>
      <Footer />
    </LayBox>
  );
};

export default Layout;
